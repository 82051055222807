import React from "react"

const InvestmentIntroduction = ({ cmsData }) => (
  <div>
    <div
      className="max-w-xl font-light text-36px leading-tight
            lg:max-w-3xl lg:text-4-5xl"
    >
      {cmsData.introductionTitle}
    </div>

    <div
      className="max-w-lg pt-4 pb-8
          md:pb-0
          lg:max-w-2xl lg:text-24px"
    >
      {cmsData.introductionDescription}
    </div>

    <div className="flex flex-col xl:flex-row items-center lg:items-start"></div>
  </div>
)

export default InvestmentIntroduction
