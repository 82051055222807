import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import InvestmentIntroduction from "./InvestmentIntroduction"
import { TeamCard } from "../Home/Team/TeamCard"

const MeetFellowsCard = styled.div`
  transition: all 0.2s ease-in-out;

  &:hover {
    //color: #03045e;
    //background: #a1cbf2;

    button {
      color: #fff;
      border-color: #fff;
    }
  }
`

const InvestmentGrid = ({
  leftColumnTeam,
  rightColumnTeam,
  entireTeam,
  cmsData,
}) => {
  const [isUserScrolling, setIsUserScrolling] = useState(false)
  const handleOnTouchMove = () => setIsUserScrolling(true)
  const handleOnTouchEnd = () => setIsUserScrolling(false)

  return (
    <>
      <div className="container">
        <div className="hidden md:flex md:justify-between">
          <div className="md:grid md:grid-cols-1 flex-1 gap-16">
            <div className="md:pb-12 xl:pb-16 2xl:pb-32">
              <InvestmentIntroduction cmsData={cmsData} />
            </div>

            {leftColumnTeam &&
              leftColumnTeam.map((member) => {
                return (
                  <TeamCard
                    name={member.name}
                    title={member.title}
                    bio={member.bio}
                    linkedInId={member.linkedInId}
                    images={member.headshot.formats}
                    textColour={member.textColour}
                  />
                )
              })}

            <Link to="/team/fellows">
              <MeetFellowsCard
                className="flex h-450px justify-center items-center bg-mvp-dark-blue cursor-pointer
                sm:h-500px md:h-400px xl:h-500px 
          "
              >
                <button
                  className="
                    inline-block
                    py-2 px-4
                    border border-mvp-light-blue rounded-full
                    uppercase tracking-widest text-15px text-mvp-light-blue
                    transition transition-colors duration-500 ease-in-out
                  "
                >
                  {cmsData.meetOurFellowsButton}
                </button>
              </MeetFellowsCard>
            </Link>
          </div>

          <div className="px-4"></div>

          <div
            className="md:grid md:grid-cols-1 flex-1 self-start gap-16 pt-72
                lg:pt-110
                xl:pt-80
                2xl:pt-72
            "
          >
            {rightColumnTeam &&
              rightColumnTeam.map((member) => {
                return (
                  <TeamCard
                    name={member.name}
                    title={member.title}
                    bio={member.bio}
                    linkedInId={member.linkedInId}
                    images={member.headshot.formats}
                    textColour={member.textColour}
                  />
                )
              })}
          </div>
        </div>
      </div>

      <div
        className="md:hidden"
        onTouchMove={handleOnTouchMove}
        onTouchEnd={handleOnTouchEnd}
      >
        <div className="container py-10">
          <InvestmentIntroduction cmsData={cmsData} />
        </div>

        {entireTeam &&
          entireTeam.map((member) => (
            <TeamCard
              name={member.name}
              title={member.title}
              bio={member.bio}
              linkedInId={member.linkedInId}
              images={member.headshot.formats}
              textColour={member.textColour}
              isUserScrolling={isUserScrolling}
            />
          ))}
        <Link to="/team/fellows">
          <MeetFellowsCard
            className="flex h-450px mt-4 justify-center items-center bg-mvp-dark-blue cursor-pointer
          "
          >
            <button
              className="
                    inline-block
                    py-2 px-4
                    border border-mvp-light-blue rounded-full
                    uppercase tracking-widest text-15px text-mvp-light-blue
                    transition transition-colors duration-500 ease-in-out
                  "
            >
              {cmsData.meetOurFellowsButton}
            </button>
          </MeetFellowsCard>
        </Link>
      </div>
    </>
  )
}

export default InvestmentGrid
