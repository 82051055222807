import React, { useContext } from "react"
import { graphql } from "gatsby"
import { sortBy, filter } from "lodash"
import { MetaTagsContext } from "../../layouts"

import InvestmentGrid from "../../components/Team/InvestmentGrid"

const InvestmentTeam = ({ data }) => {
  const cmsData = data.strapiTeam

  const { setMetaTags } = useContext(MetaTagsContext)
  setMetaTags(data.strapiTeam.seoInvestment)

  const leftInvest = filter(
    cmsData.members,
    (member) => member.teamPageDisplayColumn === "LEFT"
  )

  const rightInvest = filter(
    cmsData.members,
    (member) => member.teamPageDisplayColumn === "RIGHT"
  )

  const leftColumnTeam = sortBy(leftInvest, ["teamPageDisplayOrder"])
  const rightColumnTeam = sortBy(rightInvest, ["teamPageDisplayOrder"])
  const entireTeam = sortBy(
    [...leftInvest, ...rightInvest],
    "homePageDisplayOrder"
  )

  return (
    <div className="bg-white">
      <div className="md:py-16">
        <InvestmentGrid
          leftColumnTeam={leftColumnTeam}
          rightColumnTeam={rightColumnTeam}
          entireTeam={entireTeam}
          cmsData={cmsData}
        />
      </div>
    </div>
  )
}

export const query = graphql`
  {
    strapiTeam {
      seoInvestment {
        title
        description
      }
      members {
        bio
        title
        teamPageDisplayOrder
        teamPageDisplayColumn
        linkedInId
        name
        homePageDisplayOrder
        textColour
        headshot {
          url
          ext
          formats {
            thumbnail {
              ext
              url
            }
            small {
              url
              ext
            }
            medium {
              ext
              url
            }
            large {
              ext
              url
            }
          }
        }
      }
      meetOurFellowsButton
      investmentTeamButton
      introductionTitle
      introductionDescription
      executiveFellowsButton
    }
  }
`

export default InvestmentTeam
